export default {
    100: "#D8FFEB",

    200: "#B5E5CE",

    250: "rgba(144, 218, 182, 0.5)",

    300: "#90DAB6",

    400: "#7ED3AB",

    main: "#4AC086",

    600: "#29AB6B",

    700: "#1A9A57",

    800: "#218E59",

    900: "#157948",
};
